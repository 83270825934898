.topHeader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(34, 34, 34);
}