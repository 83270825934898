.returnToSearchButton {
    margin: 30px;
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 3px;
}

.returnToSearchButton:hover {
    margin: 30px;
}

.mainEditionView {
    display: flex;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 30px;
    width: 50vw;
    font-size: 10px;
    background-color: white;
    box-shadow: 5px 5px 10px rgb(211, 203, 203);
    border-radius: 15px;
    margin-bottom: 100px;
}

select {
    background-color: antiquewhite;
    overflow: hidden;
    width: 20vw;
}

.bookLinkList {
    margin-left: auto;
    margin-right: auto;
    padding: 10px 30px;
    width: 50vw;
    font-size: 12px;
    border: 1px solid black;
    box-shadow: 5px 5px 10px rgb(211, 203, 203);
    margin-top: 30px;
}

.bookEditionPageBody {
    margin: 100px 0;
}

@media (max-width: 600px) {
    .mainEditionView {
        flex-direction: column;
        margin-bottom: 50px;
    }
    select {
        width: 50vw;
    }
}