.popUp {
    padding: 10px 30px;
}

.modal {
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(85, 81, 81, 0.699);
}

@media (max-width: 600px) {
    .modal {
        overflow-y: scroll;
        top: 45%;
        right: -30%;
        bottom: -20%;
        left: 50%;
    }
}