.bookInfo {
    margin: 40px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

@media (max-width: 600px) {
    .bookInfo {
        margin: 30px;
        grid-template-columns: 1fr 1fr;
    }
    .error_message {
        font-size: 20px;
        padding: 0 20px;
    }
}

.error_message {
    font-size: 30px;
    margin: auto;
    margin-top: 50px;
}

.loader-position {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}