.bookLinkDisplay {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    background-color: antiquewhite;
}

.storeLinkButton {
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .bookLinkDisplay {
        font-size: 10px;
        margin: 10px 0px;
    }
    .linkItem {
        margin-left: 20px;
    }
    .storeLinkButton {
        width: 100%;
    }
}