.banner {
    margin-top: 30px;
    height: 230px;
    background: url("../../img/Books_banner_1920x400.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 600px) {
    .banner {
        padding-bottom: 150px;
    }
}