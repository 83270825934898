.footer {
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    padding: 10px 30px;
    font-size: 12px;
    background-color: rgb(223, 221, 221);
    color: black;
    font-weight: 600;
}

.footer_link {
    cursor: pointer;
    padding: 2px 10px;
    transition: 0.3s all ease-in-out;
}

.footer_link:hover {
    transform: scale(1.2);
    background-color: gray;
    color: antiquewhite;
    padding: 1px 5px;
}