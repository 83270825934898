.singlebookImg {
    height: 250px;
    width: 150px;
    display: block;
    margin-right: 0;
    box-shadow: 5px 5px 10px rgb(161, 161, 170);
    margin-bottom: 30px;
}

@media (max-width: 600px) {
    .singlebookImg {
        height: 200px;
        width: 120px;
    }
}

.singlebookName {
    font-size: 12px;
    text-align: right;
    padding-top: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}