.p_quote {
    text-align: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: oblique;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0 30px;
    padding-bottom: 80px;
    font-size: 16px;
    direction: ltr;
}

@media (min-width: 600px) {
    .p_quote {
        text-align: center;
        font-family: Georgia, "Times New Roman", Times, serif;
        font-style: oblique;
        color: rgb(255, 255, 255);
        margin: 0px;
        padding: 0 30px;
        height: 60px;
        font-size: 20px;
    }
}