.paginatedPage {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.dataContainer {
    margin: 20px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media (min-width: 600px) {
    .dataContainer {
        margin: 40px;
        text-align: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationItem {
    color: rgb(151, 151, 151);
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: relative;
    margin: 0 5px;
    font-size: 10px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background: #f7f7f7;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: #ecba04;
    margin: 0 10px;
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

.paginationItem.active {
    background-color: #ecba04;
    pointer-events: none;
    color: white;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
}