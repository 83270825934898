.bookImg {
    height: 170px;
    width: 105px;
    box-shadow: 5px 5px 10px rgb(161, 161, 170);
    transition: all 0.3s ease-in-out;
}

.bookView {
    padding: 30px;
    margin: 10px;
    text-align: right;
    font-size: 10px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .bookView {
        padding: 0px;
        margin: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.bookImg:hover {
    transform: scale(1.1);
    box-shadow: 7px 7px 5px rgb(161, 161, 170);
}

.bookName {
    font-size: 12px;
    padding-top: 5px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

a:link {
    text-decoration: none;
    color: rgb(0, 0, 0);
}