.worm {
    height: 30px;
}

.head {
    display: flex;
}

@media (max-width: 600px) {
    .worm {
        height: 20px;
    }
}