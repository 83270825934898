h1 {
    margin-top: 20px;
    text-align: center;
    color: rgb(192, 170, 74);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    text-shadow: 1px 1px 1px gray;
    font-size: 20px;
}

input {
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100px;
    color: white;
    margin-left: 5px;
}

.suggestions {
    border: 1px solid rgb(61, 60, 60);
    background-color: #222222;
    color: white;
    margin: 0px 250px;
    font-size: 12px;
    padding: 5px 10px;
    width: 40vw;
}

.suggestions:hover {
    background-color: rgb(170, 165, 165);
    color: rgb(243, 243, 247);
    box-shadow: 1px 5px 5px rgb(161 161 170);
    cursor: pointer;
}

.suggestionList {
    position: absolute;
    top: 60px;
    right: -120px;
}

.SearchIcon {
    height: 10px;
    cursor: pointer;
    padding-left: 8px;
}

.inputHolder {
    border-radius: 30px;
    border: 1px solid white;
    height: 25px;
}

.show-spinner {
    position: absolute;
    color: white;
    font-size: 16px;
    text-align: right;
    top: 60px;
    right: 30px;
    background-color: #222222;
    margin: 0px 250px;
    padding: 10px;
    width: 20vw;
}

@media (max-width: 600px) {
    .suggestionList {
        right: -210px;
    }
    .show-spinner {
        right: -210px;
    }
}